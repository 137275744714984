import React from "react";
import clsx from "clsx";
import LoaderJson from "../../assets/loader_generic.json";
import Lottie from "react-lottie";
import $ from "strings/talent";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoaderJson,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Loader = ({ msg, bgColor }) => {
  return (
    <div
      className={clsx(
        "loader flex h-full w-full flex-col items-center justify-center px-8 py-4 text-center font-lato",
        bgColor
      )}
    >
      <div className="w-full items-center justify-center sm:w-1/2">
        <Lottie options={defaultOptions} />
      </div>
      <div className="w-full items-center justify-center sm:w-1/2">
        <div className="py-2 text-2xl font-bold text-darkest-navy sm:text-3xl">
          {msg}
        </div>
        {msg && (
          <div className="text-base font-normal text-midnight sm:text-lg">
            {$.loading_subtitle}
          </div>
        )}
      </div>
    </div>
  );
};

export default Loader;
