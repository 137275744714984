import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import NotFoundPage from "./NotFound";
import Section from "components/Section";
import ProfilePage from "./Profile";
import SettingsPage from "./Settings";
import AssessmentsPage from "./Assessments";
import {
  getTalentOnboarded,
  getUrlSuffix,
  getUserId,
} from "../../utils/localStorageService";
import { getTalentDataFromUrlSuffix } from "utils/api";
import { NavBar } from "./components";

const Page = () => {
  const { id: suffix } = useParams();
  const [talentData, setTalentData] = useState();
  const [talentUserLinks, setTalentUserLinks] = useState();
  const [isPublicProfile, setIsPublicProfile] = useState(true);
  const loggedInUserId = getUserId();
  const userSuffix = getUrlSuffix();

  const history = useHistory();

  useEffect(() => {
    const onboarded = getTalentOnboarded();
    const userId = getUserId();
    if (!userId) {
      return;
    }
    if (!onboarded) {
      history.push(`/talent/onboard?user=${userId}`);
    }
  }, [history]);

  useEffect(() => {
    if (!suffix || suffix === "null") {
      window.location.href = "/404";
    }
    getTalentDataFromUrlSuffix(suffix)
      .then((response) => {
        setTalentData(response);
        setTalentUserLinks(response?.user?.user_links || []);
      })
      .catch(() => {
        window.location.href = "/404";
      });
  }, [suffix]);

  useEffect(() => {
    const forcePublic = window?.location?.search?.includes("public=true");
    setIsPublicProfile(talentData?.user?.id !== loggedInUserId || forcePublic);
  }, [talentData, loggedInUserId]);

  return (
    <Section
      id="talent_tech_page"
      className="font-lato bg-alice-blue-lighter min-h-full flex flex-col"
    >
      <div className="flex-1 justify-center">
        {talentData && (
          <NavBar talentData={talentData} isPublicProfile={isPublicProfile} />
        )}
        <Switch>
          <Route exact path="/404">
            <NotFoundPage />
          </Route>
          <Route exact path="/talent/:id">
            <ProfilePage
              talentData={talentData}
              setTalentData={setTalentData}
              talentUserLinks={talentUserLinks}
              setTalentUserLinks={setTalentUserLinks}
              isPublicProfile={isPublicProfile}
            />
          </Route>
          <Route exact path="/talent/:id/profile">
            <ProfilePage
              talentData={talentData}
              setTalentData={setTalentData}
              talentUserLinks={talentUserLinks}
              setTalentUserLinks={setTalentUserLinks}
              isPublicProfile={isPublicProfile}
            />
          </Route>
          <Route exact path="/talent/:id/settings">
            {suffix === userSuffix ? (
              <SettingsPage
                talentData={talentData}
                setTalentData={setTalentData}
                isPublicProfile={isPublicProfile}
              />
            ) : userSuffix ? (
              <Redirect to={`/talent/${userSuffix}/settings`} />
            ) : (
              <Redirect to={`/404`} />
            )}
          </Route>
          <Route exact path="/talent/:id/expertise">
            {suffix === userSuffix ? (
              <AssessmentsPage
                talentData={talentData}
                setTalentData={setTalentData}
                isPublicProfile={isPublicProfile}
              />
            ) : userSuffix ? (
              <Redirect to={`/talent/${userSuffix}/expertise`} />
            ) : (
              <Redirect to={`/404`} />
            )}
          </Route>
          <Redirect from="/talent/:id" to="/talent/:id/profile" />
          <Route exact path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </div>
    </Section>
  );
};

export default Page;
