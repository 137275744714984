import React, { useState, useMemo } from "react";
import Modal from "../components/Modal";
import $ from "strings/talent";
import { Dropdown, Input } from "../../../components/Form";
import { Button, Error } from "../components";
import { RemoveBtn } from "components/Buttons";
import {
  postTalentUserLink,
  patchTalentUserLink,
  deleteTalentUserLink,
  getTalentDataFromUrlSuffix
} from "utils/api";

const urlRegExp = new RegExp(
  '^([a-zA-Z]+:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
  'i'
);

const LINK_TYPES = [
  {label: $.general_website_label, value: 'general_website'},
  {label: $.x_twitter_label, value: 'x_twitter'},
  {label: $.facebook_label, value: 'facebook'},
  {label: $.tiktok_label, value: 'tiktok'},
  {label: $.linkedin_label, value: 'linkedin'},
  {label: $.dribble_label, value: 'dribble'},
  {label: $.behance_label, value: 'behance'},
  {label: $.pinterest_label, value: 'pinterest'},
  {label: $.vimeo_label, value: 'vimeo'},
  {label: $.youtube_label, value: 'youtube'},
  {label: $.medium_label, value: 'medium'},
  {label: $.substack_label, value: 'substack'},
  {label: $.wordpress_label, value: 'wordpress'},
]

const EditLinksForm = ({
  onClose,
  talentData,
  setTalentData
}) => {
  const { user: { user_links } } = talentData;
  const [isUpdating, setIsUpdating] = useState(false);
  const [values, setValues] = useState(user_links);
  const [errors, setErrors] = useState();
  const [toDelete, setToDelete] = useState([]);

  const linkedinExists = useMemo(() => {
    if (!user_links) return;
    return user_links.find(l => l.name.toLowerCase() === 'linkedin') ? true : false;
  }, [user_links]);

  const onClickSave = async () => {
    setIsUpdating(true);
    const linksWithErrors = values.filter(
      v => !v.url.length ||
           !v.name.length ||
           !urlRegExp.test(v.url)
    );
    if (linksWithErrors.length) {
      setErrors(linksWithErrors);
      setIsUpdating(false);
      return;
    }

    const toCreate = values.filter(v => v.id.includes('temp_'));
    const toUpdate = values.filter(v =>
      !v.id.includes('temp_') && (
        user_links.find(l => l.id === v.id)?.name !== v.name ||
        user_links.find(l => l.id === v.id)?.url !== v.url
      )
    );
    if (toUpdate.length) {
      for (const link of toUpdate) {
        delete link.talent_project_id;
        await patchTalentUserLink(link.id, link);
      }
    }

    if (toCreate.length) {
      for (const link of toCreate) {
        delete link.id;
        await postTalentUserLink(link);
      }
    }

    if (toDelete.length) {
      for (const id of toDelete) {
        try {
          await deleteTalentUserLink(id);
        } catch (e) {
          console.error(e);
        }
      }
    }

    getTalentDataFromUrlSuffix(talentData.url_suffix).then(setTalentData);
    setIsUpdating(false);
    onClose();
  }

  const onClickCancel = () => {
    onClose();
  }

  const onChangeInput = (e) => {
    const { id, value } = e.target;
    const arrToUpdate = [...values];
    const linkToUpdate = values.find(v => v.id === id);
    const linkIndex = values.findIndex(v => v.id === id);
    const newVal = {...linkToUpdate, 'url': value}
    arrToUpdate.splice(linkIndex, 1, newVal)
    setValues([...arrToUpdate])
  }

  const onChangeDropdown = (id, value) => {
    const arrToUpdate = [...values];
    const linkToUpdate = values.find(v => v.id === id);
    const linkIndex = values.findIndex(v => v.id === id);
    const newVal = { ...linkToUpdate, 'name': value }
    arrToUpdate.splice(linkIndex, 1, newVal)
    setValues([...arrToUpdate])
  }

  const onClickAddLink = () => {
    const newVal = {
      id: `temp_${values.length + 1}`,
      name: '',
      url: ''
    };
    setValues([...values, newVal]);
  }

  const onClickRemoveLink = async (id) => {
    setToDelete([...toDelete, id]);
    setValues(values.filter(v => v.id !== id));
  };

  return (
    <Modal
      title='Edit Links'
      onClose={onClickCancel}
      onClickCancel={onClickCancel}
      onClickSave={onClickSave}
      buttonsDisabled={isUpdating}
      id="edit_links_form"
    >
    <div className="flex-1 min-h-0 overflow-y-auto px-2 pt-2">
      <div className="pb-2">
        {values.map((link, i) =>
          <div key={link.id}>
            <div className="text-sm font-bold">{`Link ${i+1}`}</div>
            <div className="flex gap-2 py-1">
              <div className="flex-1">
                <Input
                  name="linkUrl"
                  type="text"
                  value={link.url}
                  id={link.id}
                  placeholder='Enter URL'
                  onChange={onChangeInput}
                />
                <div className="py-1">
                  {errors?.find(e => e.id === link.id && (!urlRegExp.test(link.url) || !link.url.length)) ?
                    <Error className="font-sm" msg='Enter a valid URL' /> : <div style={{height: '22px'}} />
                  }
                </div>
              </div>
              <div className="flex-1">
                <Dropdown
                  className="flex-1"
                  name="linkType"
                  placeholder="Select link type"
                  options={
                    linkedinExists && user_links.find(l => l.id === link.id)?.name?.toLowerCase() !== 'linkedin' ?
                    LINK_TYPES.filter(l => l.value !== "linkedin") :
                    LINK_TYPES
                  }
                  value={link.name?.toLowerCase()}
                  id={link.id}
                  onChange={(e) => onChangeDropdown(link.id, e.value)}
                />
                <div className="py-1">
                  {errors?.find(e => e.id === link.id && !link.name.length) ?
                    <Error className="font-xs" msg='Choose a link type' /> : <div style={{height: '22px'}} />
                  }
                </div>
              </div>
              <div style={{ 'height': '38px' }} className="flex items-center">
                <RemoveBtn
                  onClick={() => onClickRemoveLink(link.id)}
                  disabled={isUpdating}
                  className="rounded-xl"
                />
              </div>
            </div>
          </div>
        )}
        <div className="mt-2">
          <Button
            noMinW
            secondary
            onClick={onClickAddLink}
            disabled={isUpdating}
          >
            <span>{$.add_new_link_btn}</span>
          </Button>
        </div>
      </div>
    </div>
    </Modal>
  );
};

export default EditLinksForm;
