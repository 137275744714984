import React, { useEffect, useState } from "react";
import AboutMe from "./AboutMe";
import AdditionalLinks from "./AdditionalLinks";
import CompanyStages from "./CompanyStages";
import EngagementFlexibility from "./EngagementFlexibility";
import Header from "./Header";
import Industries from "./Industries";
import Projects from "./Projects";
import Skills from "./Skills";
import Tools from "./Tools";
import { Loader } from "../onboard/screens";
import CompletenessWizard from "./CompletenessWizard";
import { useHistory } from "react-router-dom";
import { getUrlSuffix } from "../../../utils/localStorageService";
import { Helmet } from "react-helmet";
import { SPECIALTY_OPTIONS } from "../onboard/constants";

const SPECIALTY_MAP = {};
SPECIALTY_OPTIONS.forEach((option) => {
  SPECIALTY_MAP[option.value] = option.label;
});

const ProfilePage = ({
  talentData,
  setTalentData,
  talentUserLinks,
  setTalentUserLinks,
  isPublicProfile
}) => {
  const [completenessWizardHandlers, setCompletenessWizardHandlers] = useState(
    {}
  );
  const [onAboutMeClick, setOnAboutMeClick] = useState(() => {});
  const [onProjectsClick, setOnProjectsClick] = useState(() => {});
  const [onProfilePhotoClick, setOnProfilePhotoClick] = useState(() => {});
  const [schemaData, setSchemaData] = useState({});
  const [specialties, setSpecialties] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");

  const history = useHistory();

  const userSuffix = getUrlSuffix();

  useEffect(() => {
    if (talentData) {
      const talentName = `${talentData?.user?.preferred_name || talentData?.user?.first_name} ${talentData?.user?.last_name}`;
      const title =
        talentData?.page_title ||
        `${talentName} | Growth Marketing | Right Side Up`;
      setPageTitle(title);
      const description =
        talentData?.page_description ||
        `${talentName} - Growth Marketing Consultant${specialties ? " | Expert in " + specialties : ""}`;
      setPageDescription(description);

      const { user, talent_created_projects } = talentData;

      const specialtiesArray =
        talentData?.specialties
          ?.split("|")
          .map((specialty) => SPECIALTY_MAP[specialty]) || [];
      let s = "";
      if (specialtiesArray.length === 1) {
        s = specialtiesArray[0];
      } else if (specialtiesArray.length === 2) {
        s = `${specialtiesArray[0]} and ${specialtiesArray[1]}`;
      } else if (specialtiesArray.length > 2) {
        s = `${specialtiesArray[0]}, ${specialtiesArray[1]}, and ${specialtiesArray[2]}`;
      }

      setSpecialties(s);
      const socialLinks = user.user_links.map((link) => link.url);
      const profilePhoto = user.profile_photo;
      const name = `${user.first_name} ${user.last_name}`;
      const jobTitle = "Growth Marketing Consultant";
      const uci = user.user_contact_info;
      const location = `${uci?.city}, ${uci?.country}`;

      const company = talent_created_projects?.[0]?.company?.name || "Unknown";
      setSchemaData({
        "@context": "https://schema.org",
        "@type": "Person",
        name: name,
        jobTitle: jobTitle,
        worksFor: {
          "@type": "Organization",
          name: company
        },
        image: profilePhoto,
        sameAs: socialLinks,
        homeLocation: {
          "@type": "Place",
          name: location
        }
      });
    }
  }, [talentData]);

  useEffect(() => {
    setCompletenessWizardHandlers({
      onAboutMeClick,
      onProjectsClick,
      onProfilePhotoClick,
      onSkillRatingsClick: () =>
        history.push(`/talent/${userSuffix}/expertise?tab=skills`),
      onToolRatingsClick: () =>
        history.push(`/talent/${userSuffix}/expertise?tab=tools`),
      onIndustryRatingsClick: () =>
        history.push(`/talent/${userSuffix}/expertise?tab=industries:industry`)
    });
  }, [onAboutMeClick]);

  return (
    <>
      {talentData?.seo_on ? (
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="robots" content="index, follow" />
          <script type="application/ld+json">
            {JSON.stringify(schemaData)}
          </script>
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:image" content={schemaData?.image} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="profile" />
          <meta property="og:site_name" content="Right Side Up" />
          <meta property="og:locale" content="en_US" />
        </Helmet>
      ) : (
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
        </Helmet>
      )}
      <div></div>
      <div className="h-full w-full">
        {talentData && talentUserLinks ? (
          <div className="flex flex-col p-4 md:flex-row md:p-5">
            {/** Mobile single column order. */}
            <div className="flex flex-col gap-4 md:hidden">
              <Header
                talentData={talentData}
                setTalentData={setTalentData}
                isPublicProfile={isPublicProfile}
                setEditProfilePhoto={setOnProfilePhotoClick}
              />
              <Industries
                talentData={talentData}
                isPublicProfile={isPublicProfile}
              />
              <AboutMe
                talentData={talentData}
                setTalentData={setTalentData}
                isPublicProfile={isPublicProfile}
                setOpenAboutMe={setOnAboutMeClick}
              />
              <CompletenessWizard
                talentData={talentData}
                isPublicProfile={isPublicProfile}
                onClickHandlers={completenessWizardHandlers}
              />
              <Projects
                talentData={talentData}
                setTalentData={setTalentData}
                isPublicProfile={isPublicProfile}
                setAddNewProject={setOnProjectsClick}
              />
              <EngagementFlexibility
                talentData={talentData}
                isPublicProfile={isPublicProfile}
              />
              <Skills
                talentData={talentData}
                isPublicProfile={isPublicProfile}
              />
              <Tools
                talentData={talentData}
                isPublicProfile={isPublicProfile}
              />
              <CompanyStages
                talentData={talentData}
                isPublicProfile={isPublicProfile}
              />
              <AdditionalLinks
                talentData={talentData}
                setTalentData={setTalentData}
                isPublicProfile={isPublicProfile}
              />
            </div>

            {/** Desktop two column order */}
            <div className="hidden w-full flex-col gap-4 md:flex md:w-2/3 md:pr-5">
              <Header
                talentData={talentData}
                setTalentData={setTalentData}
                isPublicProfile={isPublicProfile}
                setEditProfilePhoto={setOnProfilePhotoClick}
              />
              <AboutMe
                talentData={talentData}
                setTalentData={setTalentData}
                isPublicProfile={isPublicProfile}
                setOpenAboutMe={setOnAboutMeClick}
              />
              <Projects
                talentData={talentData}
                setTalentData={setTalentData}
                isPublicProfile={isPublicProfile}
                setAddNewProject={setOnProjectsClick}
              />
              <Skills
                talentData={talentData}
                isPublicProfile={isPublicProfile}
              />
              <Tools
                talentData={talentData}
                isPublicProfile={isPublicProfile}
              />
            </div>
            <div className="mt-4 hidden w-full flex-col gap-4 text-midnight md:mt-0 md:flex md:w-1/3">
              <CompletenessWizard
                talentData={talentData}
                isPublicProfile={isPublicProfile}
                onClickHandlers={completenessWizardHandlers}
              />
              <EngagementFlexibility
                talentData={talentData}
                isPublicProfile={isPublicProfile}
              />
              <AdditionalLinks
                talentData={talentData}
                setTalentData={setTalentData}
                isPublicProfile={isPublicProfile}
              />
              <div className="hidden md:flex">
                <Industries
                  talentData={talentData}
                  isPublicProfile={isPublicProfile}
                />
              </div>
              <CompanyStages
                talentData={talentData}
                isPublicProfile={isPublicProfile}
              />
            </div>
          </div>
        ) : (
          <Loader bgColor="bg-lightest-grey" />
        )}
      </div>
    </>
  );
};

export default ProfilePage;
